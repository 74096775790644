import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import MailchimpSubscribe from "react-mailchimp-subscribe"

import styles from "./blog.module.css"

export default ({ data }) => {
  const articles = data.allMarkdownRemark.edges.filter(
    ({ node }) =>
      node.frontmatter.posttype === "article" && !node.frontmatter.hidden
  )

  const url =
    "https://thisisundefined.us19.list-manage.com/subscribe/post?u=98bc2895d60b2f125e79385de&amp;id=f5b0225062"

  return (
    <Layout>
      <SEO
        title="Blog"
        description="Pulse is a new article series from Undefined focusing on innovators and creators breaking the fold by stepping out of their comfort zone."
      />
      <div className={styles.container}>
        <div className={styles.introDetails}>
          <div className={styles.introDetails1} />
          <div className={styles.introDetails2} />
          <div className={styles.introDetails3} />
        </div>

        <div className={styles.hero}>
          <h2 className={styles.title}>Blog</h2>
          <h3 className={styles.subtitle}>
            Stories from the{" "}
            <span className={styles.underline}>cutting edge</span> of the
            digital world
          </h3>
          <p className={styles.small_text}>
            Pulse is a new article series from Undefined focusing on innovators
            and creators breaking the fold by stepping out of their comfort
            zone.
          </p>
        </div>

        <div className={styles.main}>
          {articles.map(({ node }) => {
            return (
              <a
                className={styles.articleContainer}
                href={node.fields.slug}
                key={node.id}
              >
                <img src={node.frontmatter.image} />
                <div className={styles.articleRow}>
                  <div className={styles.articleRowDetails}>
                    <p className={styles.articleTags}>
                      {node.frontmatter.tags}
                    </p>
                    <h4 className={styles.articleTitle}>
                      {node.frontmatter.title}
                    </h4>
                  </div>
                  <div className={styles.articleRowMore}>
                    <div className={styles.slash}></div>
                    <div>READ NOW</div>
                  </div>
                </div>
              </a>
            )
          })}
          <div className={styles.newsletter}>
            <h3>We're just getting started</h3>
            <h2>
              Sign up for Pulse to get the latest uncovered stories from the
              startup world
            </h2>
            <MailchimpSubscribe url={url} />
          </div>
        </div>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            posttype
            tags
            image
            hidden
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`
